.order-list {
  .order-list-breadcrumb {
    margin-top: 24px;
    font-size: 14px;
    color: #4A4A4A;
  }

  .order-list-title {
    font-size: 28px;
    line-height: 120%;
    font-weight: 500;
    color: #4A4A4A;
    margin-top: 8px;
    margin-bottom: 12px;
  }

  .order-list-filter {
    border-bottom: 1px solid #D7D7D7;
    padding-bottom: 16px;
    margin-bottom: 16px;
  }

  .order-list-table {
    margin-bottom: 48px;
  }
}
