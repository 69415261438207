.basic-layout {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-height: 100vh;

  .location-nav-menu-wrapper {
    border-bottom: 1px solid #d7d7d7;
    box-shadow: 0 2px 5px #d7d7d7;
  }

  .layout-header {
    width: var(--root-max-width);
  }

  .content-wrapper {
    flex: 1;
    height: 100%;
    width: var(--root-max-width);
  }

  .content-wrapper-allwidth {
    width: 100%;
  }

  .layout-footer {
    width: 100%;
  }
}
