.marked-html ul {
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin-bottom: 10px !important;
}

.marked-html ul li {
    margin-left: 18px !important;
}

.marked-html h1,
.marked-html h2,
.marked-html h3 {
    font-size: 16px !important;
    font-weight: bold;
}


.marked-html h4,
.marked-html h5 {
    font-size: 14px !important;
    font-weight: bold;
}
