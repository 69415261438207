.order-title {
  height: 45px;
  border-radius: 4px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.15);
  background-color: #002C5E;
  display: flex;
  align-items: center;
  font-size: 22px;
  line-height: 130%;
  padding: 0 16px;
  color: #FFFFFF;
}
