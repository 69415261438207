.product-image-slider {
  height: 520px;
  position: relative;

  .product-image-slider-list {
    width: 100%;
    height: 100%;
    display: flex;

    .product-image-slider-item {
      padding: 0 10px;
      border-radius: 4px;
      width: 1148px;
      height: 520px;
      flex: 0 0 auto;

      .product-image-slider-image {
        background-size: cover;
        height: 100%;
        width: 100%;
        position: relative;

        .product-image-slider-mask {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(51, 51, 51, 0.5);
        }
      }
    }
  }

  .product-image-slider-arrow-left {
    position: absolute;
    left: calc((var(--root-max-width) - 1148px) / 2 - 24px);
    top: calc(50% - 24px);
    cursor: pointer;

    &.hidden {
      display: none;
    }
  }

  .product-image-slider-arrow-right {
    position: absolute;
    right: calc((var(--root-max-width) - 1148px) / 2 - 24px);
    top: calc(50% - 24px);
    cursor: pointer;

    &.hidden {
      display: none;
    }
  }
}
