.page-loading-title {
    color: #002C5E;
    font-weight: 500;
    font-size: 18px;
    line-height: 92px;
    text-align: center;

    height: 92px;
}

#chakra-modal-pageloading {
    margin-top: 280px;
}