$select-prefix: "rc-select"; 

.#{$select-prefix}-selection-search-input {
    border: none;
    padding-left: 5px;
    outline: none;
    background:none;
    width: 156px;
    height: 30px;
}

.#{$select-prefix}-selection-search-input::placeholder{
  color: #4A4A4A;
}

.#{$select-prefix} {
  display: inline-block;
  font-size: 12px;
  width: 156px;
  position: relative;

  &-disabled {
    &,
    & input {
      cursor: not-allowed;
    }

    .#{$select-prefix}-selector {
      opacity: 0.3;
    }
  }



  // ============== Selector ===============
  .#{$select-prefix}-selection-placeholder {
    // opacity: 0.4;
    pointer-events: none;
  }

  // ============== Search ===============
  .#{$select-prefix}-selection-search-input {
    appearance: none;

    &::-webkit-search-cancel-button {
      display: none;
      appearance: none;
    }
  }

  // --------------- Single ----------------
  &-single {
    .#{$select-prefix}-selector {
      display: flex;
      position: relative;

      .#{$select-prefix}-selection-search {
        width: 100%;

        &-input {
          width: 100%;
        }
      }

      .#{$select-prefix}-selection-item,
      .#{$select-prefix}-selection-placeholder {
        position: absolute;
        top: 4px;
        left: 10px;
        pointer-events: none;
        font-size: 14px;
      }
    }

    // Not customize
    &:not(.#{$select-prefix}-customize-input) {
      .#{$select-prefix}-selector {
        height: 30px;
        border: 1px solid #4A4A4A;
        box-sizing: border-box;
        border-radius: 4px;
      }
    }
  }

  // -------------- Multiple ---------------
  &-multiple .#{$select-prefix}-selector {
    display: flex;
    flex-wrap: wrap;
    padding: 1px;
    border: 1px solid #000;

    .#{$select-prefix}-selection-item {
      flex: none;
      background: #bbb;
      border-radius: 4px;
      margin-right: 2px;
      padding: 0 8px;

      &-disabled {
        cursor: not-allowed;
        opacity: 0.5;
      }
    }

    .#{$select-prefix}-selection-overflow {
      display: flex;
      flex-wrap: wrap;
      width: 100%;

      &-item {
        flex: none;
        max-width: 100%;
      }
    }

    .#{$select-prefix}-selection-search {
      position: relative;
      max-width: 100%;

      &-input,
      &-mirror {
        padding: 1px;
        font-family: system-ui;
      }

      &-mirror {
        position: absolute;
        z-index: 999;
        white-space: nowrap;
        position: none;
        left: 0;
        top: 0;
        visibility: hidden;
      }
    }
  }

  // ================ Icons ================
  &-allow-clear {
    &.#{$select-prefix}-multiple .#{$select-prefix}-selector {
      padding-right: 20px;
    }

    .#{$select-prefix}-clear {
      position: absolute;
      right: 20px;
      top: 0;
    }
  }

  &-show-arrow {
    &.#{$select-prefix}-multiple .#{$select-prefix}-selector {
      padding-right: 20px;
    }

    .#{$select-prefix}-arrow {
      pointer-events: none;
      position: absolute;
      right: 15px;
      top: 12px;
      background-size: 10px;
      height: 10px;
      width: 10px;
      background-image: url(../../assets/icons/select-icon.png);
      background-repeat: no-repeat;
      &-icon{
       
      }
    }
  }

  // =============== Focused ===============
  &-focused {
    .#{$select-prefix}-selector {
        border: 1px solid #4A4A4A;
    }
  }

  // ============== Dropdown ===============
  &-dropdown {
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    position: absolute;
    background: #fff;

    &-hidden {
      display: none;
    }
  }

  // =============== Option ================
  &-item {
    font-size: 16px;
    padding: 0px 10px;

    // >>> Group
    &-group {
      color: #999;
      font-weight: bold;
      font-size: 80%;
    }

    // >>> Option
    &-option {
        height: 30px;
        position: relative;
        text-align: center;
        font-family: PingFang SC;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        color: #8B8B8B;
        cursor: pointer;
        border-bottom: 1px solid #F2F2F2;

      &-grouped {
        padding-left: 24px;
      }

      .#{$select-prefix}-item-option-state {
        position: absolute;
        right: 0;
        top: 4px;
        pointer-events: none;
        display: none;
      }

      .#{$select-prefix}-item-option-content{
        height: 30px;
        line-height: 30px;
      }

 
      .#{$select-prefix}-item-option-state-icon {
        display: none;
      }

      // ------- Active -------
      &-active {
        .#{$select-prefix}-item-option-content{
            // background: #002C5E1A;
        }
      }

      // ------ Disabled ------
      &-disabled {
        color: #999;
      }
    }

    &-option:last-child{
        border-bottom: none;
    }

    &-option:hover{
        background-color: #002C5E1A;
    }

    &-option.#{$select-prefix}-item-option-selected{
        background-color: none;
        color: #002C5E;
    }


    // >>> Empty
    &-empty {
      text-align: center;
      color: #999;
    }
  }
}

.#{$select-prefix}-selection__choice-zoom {
  transition: all 0.3s;
}

.#{$select-prefix}-selection__choice-zoom-appear {
  opacity: 0;
  transform: scale(0.5);
}
.#{$select-prefix}-selection__choice-zoom-leave {
  opacity: 1;
  transform: scale(1);
}

.#{$select-prefix}-dropdown {
  &-slide-up-enter,
  &-slide-up-appear {
    opacity: 0;
    animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
    animation-play-state: paused;
  }

  &-slide-up-leave {
    // .effect();
    opacity: 1;
    animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
    animation-play-state: paused;
  }
}

@keyframes rcSelectDropdownSlideUpIn {
  0% {
    opacity: 0;
    transform-origin: 0% 0%;
    transform: scaleY(0);
  }
  100% {
    opacity: 1;
    transform-origin: 0% 0%;
    transform: scaleY(1);
  }
}
@keyframes rcSelectDropdownSlideUpOut {
  0% {
    opacity: 1;
    transform-origin: 0% 0%;
    transform: scaleY(1);
  }
  100% {
    opacity: 0;
    transform-origin: 0% 0%;
    transform: scaleY(0);
  }
}

@keyframes rcSelectLoadingIcon {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

.rc-virtual-list{
  padding: 6px 0;

}

//插件源代码中max-height的值写死为200px，当下拉列表数量过多时会出现滚动条，极其不美观
.rc-virtual-list-holder{
  // max-height: 500px !important;
  -ms-overflow-style: none; /* IE 10+ */
  scrollbar-width: none; /* Firefox */
}

.rc-virtual-list-holder::-webkit-scrollbar {
  display: none; 
}

