.product-item {
  height: 48px;
  border: 1px solid #002C5E;
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding: 0 24px;
  width: 100%;
  margin-bottom: 8px;
  color: #000000;

  .name {
    flex: 1 0 auto;
  }

  .price {
    flex: 0 0 120px;
    text-align: right;

    .unit {
      margin-right: 5px;
    }
  }

  .count-wrapper {
    flex: 0 0 112px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 22px;
    color: #002C5E;

    .btn {
      width: 24px;
      height: 24px;
      padding: 0;
      margin: 0;
      font-size: 14px;
      line-height: 120%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #F3F3F3;
      border-radius: 4px;
      cursor: pointer;
    }
  }
}
