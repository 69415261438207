.feature-tag {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;
  height: 48px;
  border: 2px solid #002C5E;
  border-radius: 6px;
  color: #002C5E;
  font-size: 14px;
  font-weight: 400;
  padding: 0 15px;
  margin: 0 10px;
  cursor: pointer;

  .feature-tag-content {
    display: flex;
    align-items: center;

    .feature-tag-icon {
      width: 24px;

      img {
        width: 100%;
      }
    }

    .feature-tag-label {
      margin-left: 10px;
    }
  }
}
