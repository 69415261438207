.product-cart {
  .product-cart-title {
    margin-top: 48px;
    font-size: 28px;
    font-weight: 500;
  }

  .product-cart-box {
    padding: 15px 23px;
    margin-top: 24px;
    height: 170px;
    display: flex;
    align-items: center;
    background-color: #FFFFFF;
    border-radius: 4px;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.15);

    .product-cart-box-image {
      flex: 0 0 200px;
      margin-right: 24px;
      overflow: hidden;
      height: 100%;
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }

    .product-cart-box-content {
      flex: 1 0 auto;
      height: 100%;

      .product-cart-box-content-top {
        display: flex;
        justify-content: space-between;
        padding-bottom: 12px;
        border-bottom: 1px solid #888888;

        .product-cart-box-content-name {
          font-size: 18px;
          font-weight: 500;
          color: #333333;
          line-height: 23.4px;
        }

        .product-cart-box-content-price {
          color: #FF6770;

          .price-unit {
            font-size: 16px;
            font-weight: 400;
            line-height: 22.4px;
            margin-right: 7px;
          }

          .price-value {
            font-size: 22px;
            font-weight: 500;
            line-height: 28.6px;
          }
        }
      }

      .product-cart-box-content-bottom {
        padding: 12px 0;

        .notice-item {
          font-size: 16px;
          line-height: 22.4px;
          margin-bottom: 6px;
        }
      }
    }
  }

  .product-cart-actions {
    margin-top: 24px;
    text-align: right;

    .btn {
      height: 48px;
      padding: 0 24px;
      border-radius: 6px;
      margin-left: 16px;
      font-weight: 400;

      &.warning {
        background-color: #F0D43A;
        color: #040404;
      }

      &.primary {
        background-color: #002C5E;
        color: #FFFFFF;
      }
    }
  }

  .product-cart-nearby-products {
    margin-top: 24px;

    .product-cart-common-title {
      font-size: 24px;
      font-weight: 500;
    }

    .product-list-wrapper {
      margin-top: 22px;
    }
  }
}
