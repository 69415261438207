.home-page {
  .banner-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    img.banner {
      width: 100%;
      height: 499px;
    }

    .search-wrapper {
      position: absolute;
      z-index: 1;
      text-align: center;

      .search-title {
        color: #ffffff;
        font-size: 36px;
        font-weight: 600;
      }
    }
  }

  .home-product-list-container {
  }
}
