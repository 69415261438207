.table-sort-box {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.table-sort {
  display: flex;
  align-items: center;
  margin-left: 4px;
  width: 11px;
  height: 11px;
}
