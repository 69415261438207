.layout-container {
  display: flex;

  .spacing {
    width: 132px;
    flex: 0 0 132px;
  }

  .content {
    width: 1176px;
    flex: 0 0 1176px;
  }
}
